<!--
 * @Description:服务商费用明细
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize" showBatchOperateAction>
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="itemIdList">
              <input-plus
                valueType="Array"
                v-model="searchForm.itemIdList"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="settleIdList">
              <input-plus
                valueType="Array"
                v-model="searchForm.settleIdList"
                placeholder="结算ID"
                title="结算ID"
                label="结算ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="corpName">
              <el-input
                v-model="searchForm.corpName"
                placeholder="客户名称"
                title="客户名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="engineerName">
              <el-input
                v-model="searchForm.engineerName"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneName">
              <el-input
                v-model="searchForm.sceneName"
                placeholder="车牌号"
                title="车牌号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="settleStatusList">
              <e6-vr-select
                v-model="searchForm.settleStatusList"
                :data="taskSettleStatusList"
                placeholder="账单状态"
                title="账单状态"
                clearable
                multiple
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="settleSyncStatusList">
              <e6-vr-select
                v-model="searchForm.settleSyncStatusList"
                :data="settleSyncStatusList"
                placeholder="同步财务中台状态"
                title="同步财务中台状态"
                clearable
                virtual
                multiple
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="payCompanyNo">
              <e6-vr-select
                v-model="searchForm.payCompanyNo"
                :data="serviceCompanyList"
                placeholder="支付主体"
                title="支付主体"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="serviceProviderName">
              <el-input
                v-model="searchForm.serviceProviderName"
                placeholder="服务商名称"
                title="服务商名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="workSecondClass">
              <e6-vr-select
                v-model="searchForm.workSecondClass"
                :data="taskSecondClassList"
                placeholder="工单类型"
                title="工单类型"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="zeroSettle">
              <e6-vr-select
                v-model="searchForm.zeroSettle"
                :data="emusList"
                placeholder="是否0结算"
                title="是否0结算"
                clearable
                virtual
                :is-title="true"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="saleCompanyNo">
              <e6-vr-select
                v-model="searchForm.saleCompanyNo"
                :data="organizationList"
                placeholder="销售合同主体"
                title="销售合同主体"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="isZero">
              <e6-vr-select
                v-model="searchForm.isZero"
                :data="isZeroList"
                placeholder="总费用"
                title="总费用"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="tagCodeList">
              <e6-vr-select
                v-model="searchForm.tagCodeList"
                :data="tagList"
                placeholder="标签"
                title="标签"
                multiple
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="settleMonth">
              <el-date-picker
                v-model="searchForm.settleMonth"
                type="month"
                placeholder="结算月份"
                value-format="yyyy-MM"
              ></el-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="结算单创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="结算单创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="结算单创建时间（始）"
                end-placeholder="结算单创建时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--2" prop="submitTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="工单提交时间"
                ref="effectiveDatePicker"
                v-model="searchForm.submitTime"
                title="工单提交时间"
                :picker-options="pickerOptions('searchForm.submitTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="工单提交时间（始）"
                end-placeholder="工单提交时间（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <el-button type="primary" @click="recalculate">重新计算</el-button>
          <el-button type="primary" @click="startReconciliation"
            >开始对账
          </el-button>
          <el-button type="primary" @click="cancelReconciliation">
            取消对账
          </el-button>
          <el-button type="primary" @click="confirmedAmount"
            >确认金额</el-button
          >
          <el-button type="primary" @click="selectMonth(1)">确认结算</el-button>
          <el-button type="primary" @click="updateCompany(1)"
            >基础信息变更</el-button
          >

          <i
            class="e6-icon-Import_fill"
            title="费用导入"
            @click="importXlxs"
          ></i>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
          <i
            class="e6-icon-arrow1-b_line"
            title="折叠批量按钮"
            @click="toggleBtn"
            v-if="showBatchOperate"
          ></i>
          <i
            v-else
            class="e6-icon-arrow1-t_line"
            title="显示批量按钮"
            @click="toggleBtn"
          ></i>
        </template>
        <template v-slot:batchOperateAction v-if="showBatchOperate">
          <el-button type="warning" @click="batchRecalculate"
            >批量对账</el-button
          >
          <el-button type="warning" @click="batchConfirmedAmount"
            >批量确认金额</el-button
          >
          <el-button type="warning" @click="selectMonth(2)"
            >批量确认结算</el-button
          >
          <el-button type="warning" @click="updateCompany(2)"
            >批量基础信息变更</el-button
          >
        </template>
      </table-title>
      <!-- 搜索头部 end -->
      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          v-if="tableData.length"
          highlight-current-row
          ref="elTable"
          @selection-change="handleSelectionChange"
          :summary-method="getSummaries"
          :show-summary="tableData.length ? true : false"
          :row-key="
            row => {
              return row.settleId;
            }
          "
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!--  :reserve-selection="true" -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>

          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 结算月份 -->
    <el-dialog
      v-if="monthVisible"
      v-dialogDrag
      title="结算月份"
      :visible.sync="monthVisible"
      width="400px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      append-to-body
      :element-loading-background="loadingBackground"
      custom-class="bind-dialog"
    >
      <el-form :model="monthForm" :rules="monthFormRules" ref="monthForm">
        <el-form-item prop="settleMonth" label="结算月份">
          <el-date-picker
            v-model="monthForm.settleMonth"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmBind">确定</el-button>
      </div>
    </el-dialog>
    <!-- 变更主体 -->
    <el-dialog
      v-if="companyVisible"
      v-dialogDrag
      title="基础信息变更"
      :visible.sync="companyVisible"
      width="400px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      append-to-body
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="bind-dialog"
    >
      <el-form
        :model="companyForm"
        ref="companyForm"
        label-width="100px"
        :rules="companyFormRules"
      >
        <el-form-item prop="payCorpCompanyNo" label="支付主体">
          <e6-vr-select
            v-model="companyForm.payCorpCompanyNo"
            :data="serviceCompanyList"
            placeholder="支付主体"
            title="支付主体"
            clearable
            virtual
            :is-title="true"
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item prop="invoiceType" label="发票类型">
          <e6-vr-select
            v-model="companyForm.invoiceType"
            :data="invoiceTypeList"
            placeholder="发票类型"
            title="发票类型"
            clearable
            virtual
            :is-title="true"
            :props="{
              id: 'codeValue',
              label: 'codeName'
            }"
          ></e6-vr-select>
        </el-form-item>
        <el-form-item label="税率(%):" prop="taxRate">
          <el-input
            v-model="companyForm.taxRate"
            placeholder="税率(%)"
            title="税率(%)"
            @input="limitInput($event, 'taxRate')"
          ></el-input>
        </el-form-item>
        <el-form-item label="服务费税率(%):" prop="serviceRate">
          <el-input
            v-model="companyForm.serviceRate"
            placeholder="服务费税率(%)"
            title="服务费税率(%)"
            @input="limitInput($event, 'serviceRate')"
          ></el-input>
        </el-form-item>
        <el-form-item label="开票公司名称:" prop="billingCompany">
          <el-input
            v-model="companyForm.billingCompany"
            placeholder="开票公司名称"
            title="开票公司名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="closeDialog">取消</el-button>
        <el-button type="primary" @click="confirmCompany">确定</el-button>
      </div>
    </el-dialog>
    <!-- 操作日志 -->
    <log-dialog
      :logVisible.sync="logVisible"
      :businessId="clickRow.settleId"
      logType="3"
    ></log-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import LogDialog from "@/components/logDialog.vue";
import { exportData } from "@/utils/download";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
import {
  findDownList,
  getSettleFeeList,
  exportSettleFee,
  getManualSum,
  batchConfirm,
  recalculate,
  batchSettled,
  batchStartCheck,
  updateManual,
  updateSettleInfo,
  cancelCheck,
  batchUpdate
} from "@/api";
import inputPlus from "@/components/inputPlus";
var nowdays = new Date();
var year = nowdays.getFullYear();
var month = nowdays.getMonth();
if (month == 0) {
  month = 12;
  year = year - 1;
}
if (month < 10) {
  month = "0" + month;
}
var myDate = new Date(year, month, 0);
var startDateStr = year + "-" + month + "-01 00:00:00"; //上个月第一天
var endDateStr = year + "-" + month + "-" + myDate.getDate() + " 23:59:00"; //上个月最后一天
var startDate = Date.parse(startDateStr);
var endDate = Date.parse(endDateStr);
export default {
  name: "serviceCharges",
  data() {
    return {
      queryListAPI: getSettleFeeList,
      showBatchOperate: false,
      searchForm: {
        corpName: "",
        engineerName: "",
        workSecondClass: "",
        isZero: "",
        settleSyncStatusList: [],
        zeroSettle: "",
        itemIdList: [],
        settleIdList: [],
        payCompanyNo: "",
        settleStatusList: [],
        saleCompanyNo: "",
        tagCodeList: [],
        sceneName: "",
        serviceProviderName: "",
        createTime: [startDate, endDate],
        createTimeBeg: startDate,
        createTimeEnd: endDate,
        submitTime: [],
        submitTimeBeg: "",
        submitTimeEnd: "",
        settleMonth: "",
        pageIndex: 1,
        pageSize: 20
      },
      settleSyncStatusList: [],
      emusList: [
        {
          id: 0,
          label: "否"
        },
        {
          id: 1,
          label: "是"
        }
      ],
      tagList: [],
      totalData: {},
      organizationList: [],
      taskSecondClassList: [],
      serviceCompanyList: [],
      taskSettleStatusList: [],
      total: 0,
      loading: false,
      isZeroList: [
        {
          codeValue: 1,
          codeName: "为0"
        },
        {
          codeValue: 0,
          codeName: "不为0"
        }
      ],
      columnData: [
        {
          fieldName: "itemId",
          display: true,
          fieldLabel: "工单号",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "settleId",
          display: true,
          fieldLabel: "结算ID",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workSecondClassStr",
          display: true,
          fieldLabel: "工单类型",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "visitTime",
          display: true,
          fieldLabel: "上门时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSubmitTime",
          display: true,
          fieldLabel: "工单提交时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          display: true,
          fieldLabel: "工程师",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerAttributeStr",
          display: true,
          fieldLabel: "工程师属性",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceProviderName",
          display: true,
          fieldLabel: "服务商名称",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "tagList",
          display: true,
          fieldLabel: "标签",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "账单状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "syncFinanceStatusNameList",
          display: true,
          fieldLabel: "同步财务中台状态",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "saleContractCompanyName",
          display: true,
          fieldLabel: "销售合同主体",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "payCorpCompanyName",
          display: true,
          fieldLabel: "公司支付主体",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.impl",
          display: true,
          fieldLabel: "实施费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.addParts",
          display: true,
          fieldLabel: "加装配件补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.uninstall",
          display: true,
          fieldLabel: "拆机费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.reach",
          display: true,
          fieldLabel: "上门费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.night",
          display: true,
          fieldLabel: "夜间费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.road",
          display: true,
          fieldLabel: "路补",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.empty",
          display: true,
          fieldLabel: "空跑费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.urgent",
          display: true,
          fieldLabel: "加急费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.material",
          display: true,
          fieldLabel: "材料补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.express",
          display: true,
          fieldLabel: "快递补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.nonStandard",
          display: true,
          fieldLabel: "非标补贴",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.project",
          display: true,
          fieldLabel: "项目奖罚",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.complaintsPraise",
          display: true,
          fieldLabel: "投诉/表扬/培训激励",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.workOrderLevel",
          display: true,
          fieldLabel: "工单等级奖",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.other",
          display: true,
          fieldLabel: "其他费用",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.quality",
          display: true,
          fieldLabel: "工程质检扣款",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.assign",
          display: true,
          fieldLabel: "工单下派",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.preAppoint",
          display: true,
          fieldLabel: "预约响应",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.reachOutTime",
          display: true,
          fieldLabel: "准时到达",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.delay",
          display: true,
          fieldLabel: "履约时效",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "feeMap.adjust",
          display: true,
          fieldLabel: "差额调整",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "adjustReasonStr",
          display: true,
          fieldLabel: "调整原因",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "totalAmount",
          display: true,
          fieldLabel: "总费用",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "serviceFee",
          display: true,
          fieldLabel: "服务商服务费",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "zeroSettleName",
          display: true,
          fieldLabel: "是否0结算",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "carryOutMonth",
          display: true,
          fieldLabel: "实施月份",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "settleMonth",
          display: true,
          fieldLabel: "结算月份",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerLeader",
          display: true,
          fieldLabel: "对应POE",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createBy",
          display: true,
          fieldLabel: "操作人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updateTime",
          display: true,
          fieldLabel: "最后修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      monthFormRules: {
        settleMonth: [
          {
            required: true,
            message: "请选择月份",
            trigger: ["blur", "change"]
          }
        ]
      },
      maintainVisible: false,
      monthVisible: false,
      monthForm: {
        settleMonth: ""
      },
      companyVisible: false,
      companyForm: {
        invoiceType: "",
        billingCompany: "",
        taxRate: "",
        serviceRate: "",
        payCorpCompanyNo: ""
      },
      companyFormRules: {
        // payCorpCompanyNo: [
        //   {
        //     required: true,
        //     message: "请选择支付主体",
        //     trigger: ["blur", "change"]
        //   }
        // ]
      },
      logVisible: false,
      clickRow: {},
      invoiceTypeList: [],
      dialogLoading: false,
      monthDialogType: 1, //1选择结算2批量结算
      updateType: 1 //1选择变更2批量变更
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: { inputPlus, LogDialog },
  computed: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.createTimeBeg = val[0] || "";
        this.searchForm.createTimeEnd = val[1] || "";
      }
    },
    // 创建开始时间
    "searchForm.submitTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.submitTime = this.searchForm.submitTime.reverse();
        }
        this.searchForm.submitTimeBeg = val[0] || "";
        this.searchForm.submitTimeEnd = val[1] || "";
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    //切换是否显示批量按钮
    toggleBtn() {
      this.showBatchOperate = !this.showBatchOperate;
      this.handleTableTitleResize();
    },
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList([
            "taskSecondClass",
            "serviceCompanyList",
            "taskSettleStatus",
            "organization",
            "invoiceType",
            "settleFeeTag",
            "settleSyncStatus"
          ]),
          this.getTotalData()
        ];
        let [res] = await Promise.all(promiseList);
        this.taskSecondClassList = this.getFreezeResponse(res, {
          path: "data.taskSecondClass"
        });
        this.serviceCompanyList = this.getFreezeResponse(res, {
          path: "data.serviceCompanyList"
        });
        this.taskSettleStatusList = this.getFreezeResponse(res, {
          path: "data.taskSettleStatus"
        });
        this.organizationList = this.getFreezeResponse(res, {
          path: "data.organization"
        });
        this.invoiceTypeList = this.getFreezeResponse(res, {
          path: "data.invoiceType"
        });
        this.tagList = this.getFreezeResponse(res, {
          path: "data.settleFeeTag"
        });
        this.settleSyncStatusList = this.getFreezeResponse(res, {
          path: "data.settleSyncStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    limitInput(value, name) {
      this.companyForm[name] =
        ("" + value) // 第一步：转成字符串
          .replace(/[^\d^.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
          .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
          .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
          .match(/^\d*(\.?\d{0,2})/g)[0] || "";
    },
    //查询总计
    async getTotalData() {
      try {
        let res = await getManualSum(this.searchForm);
        if (res.code == "OK") {
          this.totalData = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.queryList();
      this.getTotalData();
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        },
        {
          label: "操作日志",
          id: 3
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.routerPush({
          name: "settlementManagement/serviceChargesDetail",
          params: {
            origin: "settlementManagement/serviceCharges",
            refresh: true,
            itemId: row.itemId,
            settleId: row.settleId,
            entry: "edit"
          }
        });
      }
      if (val.id == 2) {
        this.routerPush({
          name: "settlementManagement/serviceChargesDetail",
          params: {
            origin: "settlementManagement/serviceCharges",
            refresh: true,
            itemId: row.itemId,
            settleId: row.settleId,
            entry: "detail"
          }
        });
      }
      if (val.id == 3) {
        this.clickRow = row;
        this.logVisible = true;
      }
    },
    //计算合计
    getSummaries(param) {
      const { columns } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "总计";
          return;
        }
        switch (column.property) {
          case "feeMap.impl":
            sums[index] = this.totalData.impl ? this.totalData.impl : 0;
            break;
          case "feeMap.addParts":
            sums[index] = this.totalData.addParts ? this.totalData.addParts : 0;
            break;
          case "feeMap.uninstall":
            sums[index] = this.totalData.uninstall
              ? this.totalData.uninstall
              : 0;
            break;
          case "feeMap.reach":
            sums[index] = this.totalData.reach ? this.totalData.reach : 0;
            break;
          case "feeMap.night":
            sums[index] = this.totalData.night ? this.totalData.night : 0;
            break;
          case "feeMap.road":
            sums[index] = this.totalData.road ? this.totalData.road : 0;
            break;
          case "feeMap.empty":
            sums[index] = this.totalData.empty ? this.totalData.empty : 0;
            break;
          case "feeMap.nonStandard":
            sums[index] = this.totalData.nonStandard
              ? this.totalData.nonStandard
              : 0;
            break;
          case "feeMap.project":
            sums[index] = this.totalData.project ? this.totalData.project : 0;
            break;
          case "feeMap.complaintsPraise":
            sums[index] = this.totalData.complaintsPraise
              ? this.totalData.complaintsPraise
              : 0;
            break;
          case "feeMap.workOrderLevel":
            sums[index] = this.totalData.workOrderLevel
              ? this.totalData.workOrderLevel
              : 0;
            break;
          case "feeMap.other":
            sums[index] = this.totalData.other ? this.totalData.other : 0;
            break;
          case "feeMap.quality":
            sums[index] = this.totalData.quality ? this.totalData.quality : 0;
            break;
          case "feeMap.assign":
            sums[index] = this.totalData.assign ? this.totalData.assign : 0;
            break;
          case "feeMap.preAppoint":
            sums[index] = this.totalData.preAppoint
              ? this.totalData.preAppoint
              : 0;
            break;
          case "feeMap.reachOutTime":
            sums[index] = this.totalData.reachOutTime
              ? this.totalData.reachOutTime
              : 0;
            break;
          case "feeMap.delay":
            sums[index] = this.totalData.delay ? this.totalData.delay : 0;
            break;
          case "feeMap.adjust":
            sums[index] = this.totalData.adjust ? this.totalData.adjust : 0;
            break;
          case "feeMap.urgent":
            sums[index] = this.totalData.urgent ? this.totalData.urgent : 0;
            break;
          case "feeMap.material":
            sums[index] = this.totalData.material ? this.totalData.material : 0;
            break;
          case "feeMap.express":
            sums[index] = this.totalData.express ? this.totalData.express : 0;
            break;
          case "totalAmount":
            sums[index] = this.totalData.totalAmount
              ? this.totalData.totalAmount
              : 0;
            break;
          case "serviceFee":
            sums[index] = this.totalData.serviceFee
              ? this.totalData.serviceFee
              : 0;
            break;
          default:
            break;
        }
      });
      return sums;
    },
    //重新计算
    recalculate() {
      if (!this.selColumns.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.$confirm("确认要重新计算费用吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.recalculateReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量对账
    batchRecalculate() {
      this.$confirm(
        "确认要批量变更为开始对账状态吗，开始对账后将不再允许修改驳回工单？",
        "",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          this.batchStartCheckReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量对账请求
    async batchStartCheckReq() {
      try {
        this.loading = true;
        let res = await batchStartCheck({
          ...this.searchForm,
          expectUpdateCount: this.total
        });
        if (res.code == "OK") {
          this.$message.success("批量对账操作成功");
          this.handleSearch();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    async recalculateReq() {
      try {
        this.loading = true;
        let idAndVersionNumberVOList = this.selColumns.map(item => {
          return Object.assign(
            {},
            {
              id: item.settleId,
              versionNumber: item.versionNumber
            }
          );
        });
        let res = await recalculate({
          idAndVersionNumberVOList
        });
        if (res.code == "OK") {
          this.$message.success("操作成功");

          this.handleSearch();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    //开始对账
    startReconciliation() {
      if (!this.selColumns.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.$confirm(
        "确认要变更为开始对账状态吗，开始对账后将不再允许修改驳回工单？",
        "",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }
      )
        .then(() => {
          //开始对账
          this.updateManualReq("checking");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //取消对账
    cancelReconciliation() {
      if (!this.selColumns.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.$confirm("确认要取消对账吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //开始对账
          this.cancelCheckReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //取消对账请求
    async cancelCheckReq() {
      try {
        this.loading = true;
        let settleIdList = this.selColumns.map(item => item.settleId);
        let res = await cancelCheck({
          settleIdList
        });
        if (res.code == "OK") {
          this.$message.success("取消对账成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    async updateManualReq(status, payOrgNo) {
      try {
        this.loading = true;
        let itemIdList = this.selColumns.map(item => item.itemId);
        let settleIds = this.selColumns.map(item => item.settleId);
        let res = await updateManual({
          itemIdList,
          status,
          settleIds,
          operationType: "batchUpdate",
          payOrgNo: payOrgNo ? payOrgNo : "",
          settleMonth: this.monthForm.settleMonth
            ? this.monthForm.settleMonth
            : ""
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    //变更主体
    updateCompany(type) {
      //updateType:1选中操作2批量操作
      if (type == 1) {
        if (!this.selColumns.length) {
          this.$message.warning("请先勾选数据");
          return;
        }
      }
      this.updateType = type;
      this.companyVisible = true;
    },

    confirmCompany() {
      this.$refs.companyForm.validate(valid => {
        if (valid) {
          ////选择变更主体
          if (this.updateType == 1) {
            this.updateSettleInfoReq();
          } else {
            //批量变更
            this.batchUpdateReq();
          }
        }
      });
    },
    //批量变更请求
    async batchUpdateReq() {
      try {
        //   companyForm: {
        //   invoiceType: "",
        //   billingCompany: "",
        //   taxRate: "",
        //   payCorpCompanyNo: ""
        // },
        this.dialogLoading = true;
        let res = await batchUpdate({
          ...this.searchForm,
          updateInvoiceType: this.companyForm.invoiceType,
          updateBillingCompany: this.companyForm.billingCompany,
          updateTaxRate: this.companyForm.taxRate,
          serviceRate: this.companyForm.serviceRate,
          updatePayOrgNo: this.companyForm.payCorpCompanyNo,
          expectUpdateCount: this.total
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    //
    async updateSettleInfoReq() {
      try {
        this.dialogLoading = true;
        let idAndVersionNumberVOList = this.selColumns.map(item => {
          return Object.assign(
            {},
            {
              id: item.settleId,
              versionNumber: item.versionNumber
            }
          );
        });
        let res = await updateSettleInfo({
          idAndVersionNumberVOList,
          ...this.companyForm
        });
        if (res.code == "OK") {
          this.$message.success("更新成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    //确认金额
    confirmedAmount() {
      if (!this.selColumns.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.$confirm("确认要变更为已确认金额状态吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确认金额
          this.updateManualReq("checked");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量确认金额
    batchConfirmedAmount() {
      this.$confirm("确认要批量变更为已确认金额状态吗？", "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          //确认金额
          this.batchConfirmReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //批量确认金额请求
    async batchConfirmReq() {
      try {
        this.loading = true;
        let res = await batchConfirm({
          ...this.searchForm,
          expectUpdateCount: this.total
        });
        if (res.code == "OK") {
          this.$message.success("批量更新成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    //确认结算
    selectMonth(type) {
      //type:1选择结算2:批量结算
      if (type == 1) {
        if (!this.selColumns.length) {
          this.$message.warning("请先勾选数据");
          return;
        }
      }
      this.monthVisible = true;
      this.monthDialogType = type;
    },
    //确认结算
    confirmBind() {
      this.$refs.monthForm.validate(valid => {
        if (valid) {
          //确认结算
          if (this.monthDialogType == 1) {
            this.updateManualReq("settled");
          } else {
            //批量确认结算
            this.batchSettledReq();
          }
        }
      });
    },
    //批量确认结算请求
    async batchSettledReq() {
      try {
        this.loading = true;
        let res = await batchSettled({
          ...this.searchForm,
          updateSettleMonth: this.monthForm.settleMonth,
          expectUpdateCount: this.total
        });
        if (res.code == "OK") {
          this.$message.success("批量更新成功");
          this.handleSearch();
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
        this.$refs.elTable.clearSelection();
      }
    },
    closeDialog() {
      this.monthForm = {
        settleMonth: ""
      };
      this.companyForm = {
        payOrgNo: ""
      };
      this.companyVisible = false;
      this.monthVisible = false;
    },
    //  导出
    exportData() {
      exportData(this, exportSettleFee);
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "fee_detail_import",
          origin: this.$route.name,
          serverName: E3_BL_EWOS
        }
      };
      this.routerPush(route);
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.bind-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .el-input {
      width: 250px;
    }
  }
}
</style>
